import { HTTP_URI, MAPBOX_PLACES_URI } from "../constants/uri";

export default {
  newsletter: `${HTTP_URI}/subscribe_to_newsletter`,
  user: {
    validate: HTTP_URI + "/auth/validate",
    signIn: HTTP_URI + "/auth/sign_in",
    auth: HTTP_URI + "/auth",
    deleteAvatar: HTTP_URI + "/auth/avatar",
    requestAccountDeletion: `${HTTP_URI}/account_deletion_request_no_auth`,
    confirmAccountDeletion: () =>
      `${HTTP_URI}/account_deletion_validate_no_auth`,
  },
  organizations: {
    users: {
      requestAccountDeletion: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/delete_account`,
      confirmAccountDeletion: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/delete_account_confirmed`,
    },
    mapLayers: {
      syncMapLayers: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/map_layers`,
      syncMapLayerProjectAssignments: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/map_layer_project_assignments`,
      createMapLayer: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/map_layers`,
      updateMapLayer: (organizationId, mapLayerUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/map_layers/${mapLayerUuid}/update`,
      deleteMapLayer: (organizationId, mapLayerUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/map_layers/${mapLayerUuid}/delete`
    },
    componentCatalogs: {
      syncComponentCatalogs: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/sync_component_catalogs`,
      // CATEGORY
      createComponentCatalog: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/create_component_catalog`,
      deleteComponentCatalog: (organizationId, catalogCategoryUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/component_catalog/catalog_category/${catalogCategoryUuid}`,
      updateComponentCatalog: (organizationId, catalogCategoryUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/component_catalog/catalog_category/${catalogCategoryUuid}`,
      copyComponentCatalog: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/copy_component_catalog`,
      // COMPONENT
      createCatalogComponent: (organizationId, catalogCategoryUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/component_catalog/catalog_category/${catalogCategoryUuid}/create_component`,
      deleteCatalogComponent: (organizationId, catalogComponentUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/catalog_component/${catalogComponentUuid}`,
      updateCatalogComponent: (organizationId, catalogComponentUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/catalog_component/${catalogComponentUuid}`,
      // MATERIAL
      createCatalogMaterial: (organizationId, catalogCategoryUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/component_catalog/catalog_category/${catalogCategoryUuid}/create_material`,
      deleteCatalogMaterial: (organizationId, catalogMaterialUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/catalog_material/${catalogMaterialUuid}`,
      updateCatalogMaterial: (organizationId, catalogMaterialUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/catalog_material/${catalogMaterialUuid}`,
      // DIMENSION
      createCatalogDimension: (organizationId, catalogCategoryUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/component_catalog/catalog_category/${catalogCategoryUuid}/create_dimension`,
      deleteCatalogDimension: (organizationId, catalogDimensionUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/catalog_dimension/${catalogDimensionUuid}`,
      updateCatalogDimension: (organizationId, catalogDimensionUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/catalog_dimension/${catalogDimensionUuid}`,
      // PIPE_DEFINITION
      createPipeDefinition: (organizationId, catalogCategoryUuid) =>
        `${HTTP_URI}/organizations/${organizationId}/component_catalog/catalog_category/${catalogCategoryUuid}/create_pipe_definition`,
      deletePipeDefinition: (
        organizationId,
        catalogCategoryUuid,
        catalogPipeDefinitionUuid
      ) =>
        `${HTTP_URI}/organizations/${organizationId}/component_catalog/catalog_category/${catalogCategoryUuid}/delete_pipe_definition/${catalogPipeDefinitionUuid}`,
    },
    measureLayerConfigTemplates: {
      createMeaureLayerConfigTemplate: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/create_measure_layer_config_template`,
      updateMeasureLayerConfigTemplate: (
        organizationId,
        measureLayerConfigTemplateUuid
      ) =>
        `${HTTP_URI}/organizations/${organizationId}/measure_layer_config_template/${measureLayerConfigTemplateUuid}`,
      deleteMeasureLayerConfigTemplate: (
        organizationId,
        measureLayerConfigTemplateUuid
      ) =>
        `${HTTP_URI}/organizations/${organizationId}/measure_layer_config_template/${measureLayerConfigTemplateUuid}`,
      createMeasureLayerConfigItem: (
        organizationId,
        measureLayerConfigTemplateUuid
      ) =>
        `${HTTP_URI}/organizations/${organizationId}/measure_layer_config_template/${measureLayerConfigTemplateUuid}/measure_layer_config_item`,
      updateMeasureLayerConfigItem: (
        organizationId,
        measureLayerConfigItemUuid
      ) =>
        `${HTTP_URI}/organizations/${organizationId}/measure_layer_config_item/${measureLayerConfigItemUuid}`,
      deleteMeasureLayerConfigItem: (
        organizationId,
        measureLayerConfigItemUuid
      ) =>
        `${HTTP_URI}/organizations/${organizationId}/measure_layer_config_item/${measureLayerConfigItemUuid}`,
      copyMeasureLayerConfigTemplate: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/copy_measure_layer_config_template`,
      copyMeasureLayerConfigTemplateToProject: (organizationId, projectId) =>
        `${HTTP_URI}/organizations/${organizationId}/projects/${projectId}/copy_template_items`,
      syncMeasureLayerConfigTemplates: (organizationId) =>
        `${HTTP_URI}/organizations/${organizationId}/sync_measure_layer_config_templates`,
    },
    validateProjectJobOrder: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/validate_job_order`,
    createProject: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/projects`,
    getInvoices: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/invoices`,
    sendInvitation: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/organization_invitations`,
    deleteInvitation: (invitationId) =>
      `${HTTP_URI}/organization_invitations/${invitationId}`,
    resendInvitation: (invitationId) =>
      `${HTTP_URI}/organization_invitations/${invitationId}/resend_mail`,
    removeUser: (organizationId, userId) =>
      `${HTTP_URI}/organizations/${organizationId}/users/${userId}`,
    updateUser: (organizationId, userId) =>
      `${HTTP_URI}/organizations/${organizationId}/users/${userId}`,
    deleteUserAvatar: (organizationId, userId) =>
      `${HTTP_URI}/organizations/${organizationId}/users/${userId}/avatar`,
    subscription: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/subscription`,
    createSetupIntent: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/customer/setup_intent`,
    attachPaymentMethod: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/customer/payment_method`,
    setBillingDetails: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/customer/billing_details`,
    getCustomer: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/customer`,
    getActiveSubscription: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/active`,
    reactiveSubscription: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/subscription/reactivate`,
    releaseSubscriptionSchedule: (organizationId) =>
      `${HTTP_URI}/organizations/${organizationId}/subscription/schedule`,
  },
  projects: {
    member: (id) => `${HTTP_URI}/projects/${id}`,
    assignUser: (id) => `${HTTP_URI}/projects/${id}/assign`,
    getMeasureLayerConfigs: (id) =>
      `${HTTP_URI}/projects/${id}/measure_layer_configs`,
    createMeasureLayerConfigs: (id) =>
      `${HTTP_URI}/projects/${id}/measure_layer_configs`,
    resetProject: (id) => `${HTTP_URI}/projects/${id}/reset`,
    geocodeAddress: (address, token) =>
      `${MAPBOX_PLACES_URI}/${address}.json?limit=1&types=address&access_token=${token}`,
    syncAssignedMapLayer: (projectId) =>
      `${HTTP_URI}/projects/${projectId}/map_layers`,
    assignMapLayer: (projectId, mapLayerUuid) =>
      `${HTTP_URI}/projects/${projectId}/map_layers/${mapLayerUuid}/create`,
    deleteMapLayerAssignment: (projectId, mapLayerUuid) =>
      `${HTTP_URI}/projects/${projectId}/map_layers/${mapLayerUuid}/delete`,
  },
  measureLayerConfigs: {
    updateMeasureLayerConfig: (id) => `${HTTP_URI}/measure_layer_configs/${id}`,
    deleteMeasureLayerConfig: (id) => `${HTTP_URI}/measure_layer_configs/${id}`,
  },
  mediaFiles: {
    deleteMediaFile: (id) => `${HTTP_URI}/media_files/${id}`,
  },
  projectFiles: {
    createProjectFile: (id) => `${HTTP_URI}/projects/${id}/project_files`,
    deleteProjectFile: (id) => `${HTTP_URI}/project_files/${id}`,
    getProjectFiles: (id) => `${HTTP_URI}/projects/${id}/project_files`,
  },
  measurements: {
    updateMeasurement: (id) => `${HTTP_URI}/measurements/${id}`,
  },
  measurementLines: {
    updateMeasurementLine: (id) => `${HTTP_URI}/measurement_line/${id}`,
  },
  measurementSegments: {
    updateMeasurementSegment: (id) => `${HTTP_URI}/measurement_segment/${id}`,
  },
  volumes: {
    updateVolume: (id) => `${HTTP_URI}/volumes/${id}`,
    deleteVolume: (id) => `${HTTP_URI}/volumes/${id}`,
  },
  topographicPoints: {
    updateTopographicPoint: (id) => `${HTTP_URI}/topographic_points/${id}`,
  },
  registrationAdmin: `${HTTP_URI}/auth/demo`,
  registrationUser: `${HTTP_URI}/auth`,
  registrationFreeAccount: `${HTTP_URI}/auth/registration/free`,
  confirmation: `${HTTP_URI}/auth/confirmation`,
  plans: `${HTTP_URI}/plans`,
  sync: {
    standard: `${HTTP_URI}/sync/web`,
    archived: `${HTTP_URI}/sync/web/archived`,
  },
  resetPassword: `${HTTP_URI}/auth/password_reset`,
};
